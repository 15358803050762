import React from 'react';
import './Loading.scss';

const Loading = () => (
  <div className="loading-container">
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
    <div className="loading-text">
      <span>Loading more products...</span>
    </div>
  </div>
);

export default Loading;
