import React from 'react'
import { Link } from 'gatsby'
import { compileQuery } from '../utils/helpers'
import './Pagination.scss'

const ProductsPagination = props => {
  const {
    pageContext,
    handleUpdate,
    showCounts = true,
    pathPrefix,
    location,
  } = props
  if (!pageContext) {
    return null
  }
  const {
    previousPagePath = null,
    nextPagePath = null,
    numberOfPages = null,
  } = pageContext
  const currentPageNumber = nextPagePath ? nextPagePath - 1 : numberOfPages
  const pages = [...Array.from(Array(numberOfPages).keys()).map(n => n + 1)]
  return (
    <div className="pagination-container">
      <div className="wrapper">
        <nav className="pagination" role="navigation">
          <div className="navbar navbar-menu">
            {previousPagePath > 0 && (
              <Link
                onClick={e =>
                  handleUpdate(
                    e,
                    previousPagePath,
                    compileQuery('page', previousPagePath)
                  )
                }
                to={
                  previousPagePath !== 1
                    ? `${location.pathname}${compileQuery(
                        'page',
                        previousPagePath
                      )}`
                    : pathPrefix
                }
                className="prev"
              >
                Previous
              </Link>
            )}
            {showCounts &&
              pages.map((key, index) => {
                if (
                  index < currentPageNumber + 4 &&
                  index > currentPageNumber - 6
                ) {
                  const pageTo =
                    key !== 1
                      ? `${compileQuery('page', key)}`
                      : `${compileQuery(null, null, 'page')}`
                  return (
                    <div className="page" key={index}>
                      <Link
                        onClick={e => handleUpdate(e, key, pageTo)}
                        to={`${location.pathname}${pageTo}`}
                        className={
                          `${location.pathname}${location.search}` ===
                          `${location.pathname}${pageTo}`
                            ? 'active'
                            : ''
                        }
                      >
                        {key}
                      </Link>
                    </div>
                  )
                }
                return null
              })}
            {nextPagePath && (
              <Link
                onClick={e =>
                  handleUpdate(
                    e,
                    nextPagePath,
                    compileQuery('page', nextPagePath)
                  )
                }
                to={`${location.pathname}${compileQuery('page', nextPagePath)}`}
                className="next"
              >
                Next
              </Link>
            )}
          </div>
        </nav>
      </div>
    </div>
  )
}

export default ProductsPagination
