import React, { Component, useState } from 'react';
import fetch from 'isomorphic-fetch';
import { StaticQuery, graphql, navigate } from 'gatsby';
import Loading from '../Loading';
import { getUrlVars, childCategories, compileQuery } from '../../utils/helpers';
import SearchBar from '../SearchBar';
import ProductsList from './ProductsList';
import Pagination from '../Pagination';
import './ProductsFilter.scss';

class ThisProductsFilter extends Component {
  constructor(props) {
    const pageAttr = getUrlVars()
    super(props);
    this.state = {
      loading: true,
      products: [],
      error: null,
      search: props.query || '',
      holdSearchVal: null,
      activeCategoryId: props.activeCategoryId ? props.activeCategoryId : null,
      activeSupplierId: props.activeSupplierId ? props.activeSupplierId : null,
      totalProductCount: 0,
      totalProductPages: 0,
      page: pageAttr.page ? pageAttr.page : 1
    }
  }

  componentDidMount() {
    this.getProducts();
  }

  handleUpdate = (e, page, pageTo) => {
    e.preventDefault()
    const { location } = this.props;
    this.setState({
      page: page
    }, () => {
      this.getProducts();
      navigate(`${location.pathname}${pageTo}`)
    });
  }

  handleSearchSubmit = ( e ) => {
    e.preventDefault()
    this.setState({
      page: 1,
      holdSearchVal: e.target.elements.search.value
    }, () => {
      this.getProducts();
      navigate(`${location.pathname}${compileQuery(null, null, 'page')}`);
    });
  }

  handleSearchBarUpdate = (e) => {
    const { location } = this.props;
    this.setState({
      search: e.target.value,
    })
  }

  getProducts = () => {
    const pageAttr = getUrlVars()
    const { page, activeSupplierId } = this.state
    const supplier = activeSupplierId ? `&supplier=${activeSupplierId}` : ''
    const productsUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/catalogue/v4/api?search=${encodeURI(this.state.search)}&page=${page}${supplier}`

    this.setState({ loading: true });
    fetch(productsUrl)
      .then(async (res) => {
        const total = res.headers.get( 'x-wp-total' )
        const totalpages = res.headers.get( 'x-wp-totalpages' )
        const data = await res.json();
        this.setState({ loading: false, products: data, totalProductCount: total, totalProductPages: totalpages });
      })
      .catch(error => {
        this.setState({ loading: false, error });
      })
  }

  render() {
    const { products, loading, commodity, sector, search, activeCategoryId, activeSupplierId, error, totalProductCount, holdSearchVal } = this.state
    const { hideProductList = false, location, activeSupplierName, query, productLinks, supplierLinks } = this.props;
    const pageAttr = getUrlVars()
    let filterProducts = products;
    // const relatedCategories = childCategories(activeCategoryId, categories.nodes)

    //if (activeSupplierId) {
      //filterProducts = filterProducts && filterProducts.filter( i => i.supplierId && i.supplierId === activeSupplierId )
    //}
    // if (relatedCategories) {
    //   filterProducts = filterProducts && filterProducts.filter( i => i.categoryId && typeof(relatedCategories[i.categoryId]) !== "undefined" )
    // }

    //if (search) {
      //filterProducts = filterProducts && filterProducts.filter( i => (i.partNumber+i.description).toLowerCase().includes(search.toLowerCase()) )
    //}

    if (pageAttr) {
      Object.keys(pageAttr).forEach(att => {
        if (att !== 'page') {
          var attId = this.state[att] && this.state[att].find(e => e.node.slug === pageAttr[att] )
          // Get tax ID
          if (attId && attId.wordpress_id) {
            filterProducts = filterProducts.filter( i => i[att].find(c => c === parseInt(attId.wordpress_id) ) )
          }
        }}
      )
    }

    const paged = pageAttr.page ? pageAttr.page : null;
    const itemCount = totalProductCount;
    const perPage = 20; //Match to wp api
    const numberOfPages = Math.ceil(itemCount / perPage);
    const nextPage = paged ? ( parseInt(paged) + 1 ) : 2;
    const prevPage = paged ? parseInt(paged) - 1 : null;
    const range = paged ? perPage * paged : perPage;

    const pageContext = {
      previousPagePath: prevPage,
      nextPagePath: nextPage <= numberOfPages ? nextPage : null,
      numberOfPages
    }

    return (
      <div className="products-filter-container">
        <div className="wrapper">
          <form onSubmit={event => this.handleSearchSubmit(event)}>
            <div className={`search-bar ${search ? 'active' : ''}`}>
              <div className="input-container">
                <input
                  onChange={event => this.handleSearchBarUpdate(event)}
                  id="searchbar"
                  type="text"
                  name="search"
                  value={search}
                  autoComplete='off'
                />
                <label htmlFor="searchbar" className="placeholder">Click here to search for 30,000+ products</label>
                <button
                  type="button"
                  className="search-icon"
                  onClick={() => navigateTo ? navigate(navigateTo, {state: {searchBarQuery}}) : null}
                />
              </div>
            </div>
          </form>
          <div className="search-text">
            <h2>Search results</h2>
            <p>
              {!loading && `
                We have ${totalProductCount} ${filterProducts.length === 1 ? 'item' : 'items'} listed
                ${holdSearchVal || query ? `for '${holdSearchVal || query}' ` : ''}in our
                catalogue${!loading && activeSupplierId && activeSupplierName ? ` supplied by ${activeSupplierName}` : ''}.
              `}
              {`Click on a part number below for more information and relevant contact details.`}
            </p>
          </div>
          {filterProducts && !hideProductList && (
            <>
              <ProductsList
                loading={loading}
                products={filterProducts}
                range={range}
                loadedItemCount={products.length}
                productLinks={productLinks}
                supplierLinks={supplierLinks}
                perPage={perPage}
                data={this.state}
                error={error}
                location={location}
              />
              {filterProducts.length > 0 && (
                <Pagination
                  handleUpdate={this.handleUpdate}
                  pageContext={pageContext}
                  pathPrefix="/products/search/"
                  location={location}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}


export const ProductsFilter = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              paginate
            }
          }
        }
      `}
      render={data => <ThisProductsFilter {...props} data={data} />}
    />
  );
}
