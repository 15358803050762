import React from 'react';
import OnVisible from 'react-on-visible';
import { decodeEntities } from '../../utils/htmlParse';
import './TextHeader.scss';

export const TextHeader = ({ smallHeading, heading, subHeading }) => {
  return (
    <OnVisible wrappingElement="section" className="text-header">
      <div className="wrapper">
        {smallHeading && (
          <span className="text-header-small-heading">
            {decodeEntities(smallHeading)}
          </span>
        )}
        {heading && <h1 className="text-header-heading">{decodeEntities(heading)}</h1>}
        {subHeading && <p className="text-header-subheading">{decodeEntities(subHeading)}</p>}
      </div>
    </OnVisible>
  );
};
